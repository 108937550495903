import React, { useState, useEffect } from 'react';
import { useDarkMode } from '../DarkModeContext';
import { useLocation } from '../LocationContext';
import WhatsNew from '../components/WhatsNew';
import NewsletterSubscription from '../components/NewsletterSubscription';
import PaginatedDealList from '../components/PaginatedDealList';
import '../App.css';

function Home() {
  const { darkMode } = useDarkMode();
  const { location } = useLocation();
  const [deals, setDeals] = useState([]);
  const [categories, setCategories] = useState([]);
  const [days, setDays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDeals();
    fetchCategories();
    fetchDays();
  }, []);

  const fetchDeals = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://burlingtondeals.ca/api/api.php?category=Deals');
      const data = await response.json();
      if (data.success) {
        setDeals(data.data);
      } else {
        throw new Error(data.error || 'Failed to fetch deals');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://burlingtondeals.ca/api/api.php?action=getCategories');
      const data = await response.json();
      if (data.success) {
        setCategories(data.data);
      } else {
        throw new Error(data.error || 'Failed to fetch categories');
      }
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const fetchDays = async () => {
    try {
      const response = await fetch('https://burlingtondeals.ca/api/api.php?action=getDays');
      const data = await response.json();
      if (data.success) {
        setDays(data.data);
      } else {
        throw new Error(data.error || 'Failed to fetch days');
      }
    } catch (err) {
      console.error('Error fetching days:', err);
    }
  };

  return (
    <div className={`container mx-auto px-4 py-8 max-w-7xl ${darkMode ? 'dark' : ''}`}>
      <WhatsNew />
      {isLoading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {!isLoading && !error && (
        <PaginatedDealList 
          deals={deals}
          itemsPerPage={10}
          location={location}
          isEventPage={false}
          categories={categories}
          days={days}
        />
      )}
      <NewsletterSubscription />
    </div>
  );
}

export default Home;