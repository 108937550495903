import React, { useState, useEffect } from 'react';
import { useDarkMode } from '../DarkModeContext';
import { useLocation } from '../LocationContext';
import PaginatedDealList from '../components/PaginatedDealList';
import '../App.css';

function Events() {
  const { darkMode } = useDarkMode();
  const { location } = useLocation();
  const [events, setEvents] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [days, setDays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEvents();
    fetchEventTypes();
    fetchDays();
  }, []);

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://burlingtondeals.ca/api/api.php?category=Events');
      const data = await response.json();
      if (data.success) {
        setEvents(data.data);
      } else {
        throw new Error(data.error || 'Failed to fetch events');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEventTypes = async () => {
    try {
      const response = await fetch('https://burlingtondeals.ca/api/api.php?action=getEventTypes');
      const data = await response.json();
      if (data.success) {
        setEventTypes(data.data);
      } else {
        throw new Error(data.error || 'Failed to fetch event types');
      }
    } catch (err) {
      console.error('Error fetching event types:', err);
    }
  };

  const fetchDays = async () => {
    try {
      const response = await fetch('https://burlingtondeals.ca/api/api.php?action=getDays');
      const data = await response.json();
      if (data.success) {
        setDays(data.data);
      } else {
        throw new Error(data.error || 'Failed to fetch days');
      }
    } catch (err) {
      console.error('Error fetching days:', err);
    }
  };

  return (
    <div className={`container mx-auto px-4 py-8 ${darkMode ? 'dark' : ''}`}>
      <h1 className="text-3xl font-bold text-indigo-600 dark:text-indigo-400 mb-6">Events in Burlington</h1>
      {isLoading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {!isLoading && !error && (
        <PaginatedDealList 
          deals={events}
          itemsPerPage={10}
          location={location}
          isEventPage={true}
          eventTypes={eventTypes}
          days={days}
        />
      )}
    </div>
  );
}

export default Events;