import React from 'react';
import { motion } from 'framer-motion';

function TermsOfService() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-indigo-600 dark:text-indigo-400 mb-6">Terms of Service</h1>
      <div className="text-gray-700 dark:text-gray-300 space-y-4">
        <p>
          Welcome to Burlington Deals. By using our website and services, you agree to comply with and be bound by the following terms and conditions.
        </p>
        <h2 className="text-2xl font-semibold text-indigo-600 dark:text-indigo-400 mt-4">Use of Service</h2>
        <p>
          You agree to use Burlington Deals for lawful purposes only and in a way that does not infringe upon the rights of others or restrict their use of the service.
        </p>
        <h2 className="text-2xl font-semibold text-indigo-600 dark:text-indigo-400 mt-4">User Accounts</h2>
        <p>
          You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
        </p>
        <h2 className="text-2xl font-semibold text-indigo-600 dark:text-indigo-400 mt-4">Content</h2>
        <p>
          We do not guarantee the accuracy, completeness, or usefulness of any information on the site and will not be responsible for any errors or omissions in the content.
        </p>
        <h2 className="text-2xl font-semibold text-indigo-600 dark:text-indigo-400 mt-4">Modifications to Service</h2>
        <p>
          We reserve the right to modify or discontinue the service with or without notice at any time.
        </p>
        <p>
          By using Burlington Deals, you acknowledge that you have read and understood these Terms of Service and agree to be bound by them.
        </p>
      </div>
    </motion.div>
  );
}

export default TermsOfService;