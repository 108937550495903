import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronDown, FaChevronUp, FaChevronLeft, FaChevronRight, FaSort, FaFilter } from 'react-icons/fa';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogClose } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../components/ui/select";

const PaginatedDealList = ({ deals, itemsPerPage = 10, location, isEventPage, eventTypes = [], categories = [], days = [] }) => {
  const [expandedDeal, setExpandedDeal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filters, setFilters] = useState({
    business: 'all',
    day: 'all',
    category: 'all',
    price: 'all',
    distance: 'all'
  });
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const toggleDeal = (index) => {
    setExpandedDeal(expandedDeal === index ? null : index);
  };

  const calculateDistance = (deal) => {
    if (!location || !deal.geometry_location_lat || !deal.geometry_location_lng) {
      return Infinity;
    }
    const R = 6371; // Radius of the Earth in km
    const dLat = (deal.geometry_location_lat - location.lat) * Math.PI / 180;
    const dLon = (deal.geometry_location_lng - location.lng) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(location.lat * Math.PI / 180) * Math.cos(deal.geometry_location_lat * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c; // Distance in km
  };

  const sortedDeals = React.useMemo(() => {
    let sortableDeals = [...deals];
    if (sortConfig.key) {
      sortableDeals.sort((a, b) => {
        if (sortConfig.key === 'distance') {
          return calculateDistance(a) - calculateDistance(b);
        }
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableDeals;
  }, [deals, sortConfig, location]);

  const filteredDeals = sortedDeals.filter(deal => {
    return (
      (filters.business === 'all' || deal.business_name === filters.business) &&
      (filters.day === 'all' || deal.day === filters.day) &&
      (filters.category === 'all' || 
       (isEventPage ? deal.second_category === filters.category : deal.category === filters.category)) &&
      (filters.price === 'all' || (deal.price && parseFloat(deal.price) <= parseInt(filters.price))) &&
      (filters.distance === 'all' || calculateDistance(deal) <= parseInt(filters.distance))
    );
  });

  const indexOfLastDeal = currentPage * itemsPerPage;
  const indexOfFirstDeal = indexOfLastDeal - itemsPerPage;
  const currentDeals = filteredDeals.slice(indexOfFirstDeal, indexOfLastDeal);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const formatTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minutes} ${ampm}`;
  };

  const renderFilter = (filterKey, placeholder, options) => (
    <Select onValueChange={(value) => setFilters({...filters, [filterKey]: value})}>
      <SelectTrigger className="w-full">
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">All {placeholder}s</SelectItem>
        {options.map(option => (
          <SelectItem key={option} value={option}>{option}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  );

  const renderSortableHeader = (key, label) => (
    <th className="px-4 py-3 text-left">
      <div className="flex flex-col">
        <button 
          className="text-left cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-600 px-2 py-1 rounded"
          onClick={() => requestSort(key)}
        >
          {label} {sortConfig.key === key && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
        </button>
        {key === 'business_name' && renderFilter('business', 'Business', [...new Set(deals.map(deal => deal.business_name))])}
        {key === 'day' && renderFilter('day', 'Day', days)}
        {key === 'category' && renderFilter('category', 'Category', isEventPage ? eventTypes : categories)}
        {key === 'price' && renderFilter('price', 'Price', ['10', '20', '30'])}
        {key === 'distance' && renderFilter('distance', 'Distance', ['1', '2', '5', '10'])}
      </div>
    </th>
  );

  return (
    <div className="w-full bg-gray-100 dark:bg-gray-900 rounded-lg shadow-lg">
      {/* Floating filter button for mobile */}
      <div className="md:hidden fixed bottom-4 right-4 z-10">
        <Button onClick={() => setShowFiltersModal(true)} className="rounded-full p-4">
          <FaFilter />
        </Button>
      </div>

      {/* Filters modal for mobile */}
      <Dialog open={showFiltersModal} onOpenChange={setShowFiltersModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Filters</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            {renderFilter('business', 'Business', [...new Set(deals.map(deal => deal.business_name))])}
            {renderFilter('day', 'Day', days)}
            {renderFilter('category', 'Category', isEventPage ? eventTypes : categories)}
            {renderFilter('price', 'Price', ['10', '20', '30'])}
            {renderFilter('distance', 'Distance', ['1', '2', '5', '10'])}
          </div>
          <DialogClose asChild>
            <Button>Apply Filters</Button>
          </DialogClose>
        </DialogContent>
      </Dialog>

      {/* Desktop Table View */}
      <div className="hidden md:block overflow-x-auto">
        <table className="w-full bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 rounded-lg overflow-hidden">
          <thead className="bg-gray-200 dark:bg-gray-700">
            <tr>
              {renderSortableHeader('business_name', 'Business')}
              {renderSortableHeader('day', 'Day')}
              {renderSortableHeader('category', 'Category')}
              {renderSortableHeader('price', 'Price')}
              {renderSortableHeader('distance', 'Distance')}
              <th className="px-4 py-3 text-left">Details</th>
            </tr>
          </thead>
          <tbody>
            {currentDeals.map((deal, index) => (
              <React.Fragment key={index}>
                <tr className="border-t border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-150 ease-in-out cursor-pointer" onClick={() => toggleDeal(index)}>
                  <td className="px-4 py-3">{deal.business_name}</td>
                  <td className="px-4 py-3">{deal.day}</td>
                  <td className="px-4 py-3">
                    {isEventPage ? deal.second_category || 'Event' : deal.category}
                  </td>
                  <td className="px-4 py-3">{deal.price ? `$${parseFloat(deal.price).toFixed(2)}` : 'N/A'}</td>
                  <td className="px-4 py-3">{location ? `${calculateDistance(deal).toFixed(2)} km` : 'N/A'}</td>
                  <td className="px-4 py-3">{expandedDeal === index ? <FaChevronUp /> : <FaChevronDown />}</td>
                </tr>
                <AnimatePresence>
                  {expandedDeal === index && (
                    <motion.tr
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <td colSpan="6" className="px-4 py-3 bg-gray-100 dark:bg-gray-800">
                        <div className="text-sm">
                          <p><strong>Description:</strong> {deal.description}</p>
                          <p><strong>Address:</strong> 
                            <a 
                              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(deal.formatted_address)}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 ml-1"
                            >
                              {deal.formatted_address}
                            </a>
                          </p>
                          {deal.website && (
                            <p>
                              <strong>Website:</strong> 
                              <a href={deal.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 ml-1">
                                {deal.website}
                              </a>
                            </p>
                          )}
                          <p><strong>Rating:</strong> {deal.rating ? `${parseFloat(deal.rating).toFixed(1)} ★ (${deal.user_ratings_total} reviews)` : 'N/A'}</p>
                          {deal.start_time && deal.end_time && (
                            <p><strong>Time:</strong> {formatTime(deal.start_time)} - {formatTime(deal.end_time)}</p>
                          )}
                        </div>
                      </td>
                    </motion.tr>
                  )}
                </AnimatePresence>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile Card View */}
      <div className="md:hidden space-y-4">
        {currentDeals.map((deal, index) => (
          <div key={index} className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4">
            <h3 className="text-lg font-semibold text-indigo-600 dark:text-indigo-400">{deal.business_name}</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">{deal.day} | {isEventPage ? deal.second_category || 'Event' : deal.category}</p>
            <p className="text-sm font-medium">{deal.price ? `$${parseFloat(deal.price).toFixed(2)}` : 'N/A'}</p>
            <p className="text-sm">{location ? `${calculateDistance(deal).toFixed(2)} km` : 'N/A'}</p>
            <Button
              onClick={() => toggleDeal(index)}
              className="mt-2 w-full justify-center"
              variant="outline"
            >
              {expandedDeal === index ? 'Hide Details' : 'Show Details'}
            </Button>
            <AnimatePresence>
              {expandedDeal === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="mt-2 text-sm"
                >
                  <p><strong>Description:</strong> {deal.description}</p>
                  <p>
                    <strong>Address:</strong>
                    <a 
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(deal.formatted_address)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 ml-1"
                    >
                      {deal.formatted_address}
                    </a>
                  </p>
                  {deal.website && (
                    <p>
                      <strong>Website:</strong> 
                      <a href={deal.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 ml-1">
                        {deal.website}
                      </a>
                    </p>
                  )}
                  <p><strong>Rating:</strong> {deal.rating ? `${parseFloat(deal.rating).toFixed(1)} ★ (${deal.user_ratings_total} reviews)` : 'N/A'}</p>
                  {deal.start_time && deal.end_time && (
                    <p><strong>Time:</strong> {formatTime(deal.start_time)} - {formatTime(deal.end_time)}</p>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="mt-4 flex justify-between items-center bg-gray-200 dark:bg-gray-700 px-4 py-3 rounded-b-lg">
        <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className="bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300">
          <FaChevronLeft />
        </Button>
        <span className="text-gray-700 dark:text-gray-300">
          Page {currentPage} of {Math.ceil(filteredDeals.length / itemsPerPage)}
        </span>
        <Button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredDeals.length / itemsPerPage)} className="bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300">
          <FaChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default PaginatedDealList;