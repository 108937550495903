import React from 'react';

function WhatsNew() {
  return (
    <div className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white p-6 rounded-lg mb-8 shadow-lg">
      <h2 className="text-2xl font-bold mb-4">What's New</h2>
      <ul className="space-y-2">
        <li className="flex items-center">
          <span className="mr-2">🎉</span>
          New restaurant added: Gator Ted's!
        </li>
        <li className="flex items-center">
          <span className="mr-2">🔥</span>
          Updated deals for Black Swan
        </li>
        <li className="flex items-center">
          <span className="mr-2">💸</span>
          Now showing price per wing for applicable deals
        </li>
      </ul>
    </div>
  );
}

export default WhatsNew;
