import React from 'react';
import { motion } from 'framer-motion';

function About() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-indigo-600 dark:text-indigo-400 mb-6">About Burlington Deals</h1>
      <p className="text-gray-700 dark:text-gray-300 mb-4">
        Burlington Deals is your go-to platform for finding the best deals and discounts in Burlington, Ontario. Our mission is to connect local businesses with savvy consumers, helping you save money while supporting the local economy.
      </p>
      <p className="text-gray-700 dark:text-gray-300 mb-4">
        We partner with a wide range of businesses, from restaurants and bars to retail shops and service providers, to bring you exclusive offers and promotions. Whether you're looking for a great meal, a fun night out, or a bargain on your favorite products, Burlington Deals has got you covered.
      </p>
      <p className="text-gray-700 dark:text-gray-300">
        Our team is dedicated to curating the best deals and ensuring that our platform is easy to use and always up-to-date. We're constantly working to improve your experience and bring you even more great offers from Burlington's finest establishments.
      </p>
    </motion.div>
  );
}

export default About;