import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FaMoon, FaSun, FaBars } from 'react-icons/fa';
import Home from './pages/home';
import Events from './pages/Events'; // Add this line
import About from './pages/about';
import Contact from './pages/contact';
import PrivacyPolicy from './pages/privacypolicy';
import TermsOfService from './pages/termsofservice';
import Footer from './components/Footer';
import { DarkModeProvider, useDarkMode } from './DarkModeContext';
import { LocationProvider } from './LocationContext';
import './App.css';

function AppContent() {
  const { darkMode, toggleDarkMode } = useDarkMode();
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  return (
    <Router>
      <div className={`min-h-screen flex flex-col ${darkMode ? 'dark' : ''}`}>
        <header className="bg-white dark:bg-gray-800 border-b shadow-sm">
          <div className="container mx-auto px-4 py-4 flex justify-between items-center">
            <Link to="/" className="text-2xl md:text-3xl font-bold text-indigo-600 dark:text-indigo-400">Burlington Deals</Link>
            <nav className="hidden md:flex items-center">
              <ul className="flex space-x-4 mr-4">
                <li><Link to="/" className="text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400">Home</Link></li>
                <li><Link to="/events" className="text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400">Events</Link></li>
                <li><Link to="/about" className="text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400">About</Link></li>
                <li><Link to="/contact" className="text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400">Contact</Link></li>
              </ul>
              <button 
                onClick={toggleDarkMode} 
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
              >
                {darkMode ? <FaSun /> : <FaMoon />}
              </button>
            </nav>
            <div className="md:hidden flex items-center">
              <button 
                onClick={toggleDarkMode} 
                className="p-2 mr-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
              >
                {darkMode ? <FaSun /> : <FaMoon />}
              </button>
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
              >
                <FaBars />
              </button>
            </div>
          </div>
          {mobileMenuOpen && (
            <nav className="md:hidden bg-white dark:bg-gray-800 py-2">
              <ul className="flex flex-col items-center space-y-2">
                <li><Link to="/" className="text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400" onClick={() => setMobileMenuOpen(false)}>Home</Link></li>
                <li><Link to="/events" className="text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400" onClick={() => setMobileMenuOpen(false)}>Events</Link></li>
                <li><Link to="/about" className="text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400" onClick={() => setMobileMenuOpen(false)}>About</Link></li>
                <li><Link to="/contact" className="text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400" onClick={() => setMobileMenuOpen(false)}>Contact</Link></li>
              </ul>
            </nav>
          )}
        </header>
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/events" element={<Events />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

function App() {
  return (
    <DarkModeProvider>
      <LocationProvider>
        <AppContent />
      </LocationProvider>
    </DarkModeProvider>
  );
}

export default App;