import React from 'react';
import { motion } from 'framer-motion';

function PrivacyPolicy() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-indigo-600 dark:text-indigo-400 mb-6">Privacy Policy</h1>
      <div className="text-gray-700 dark:text-gray-300 space-y-4">
        <p>
          At Burlington Deals, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our website or services.
        </p>
        <h2 className="text-2xl font-semibold text-indigo-600 dark:text-indigo-400 mt-4">Information We Collect</h2>
        <p>
          We may collect personal information such as your name, email address, and location when you sign up for our services or interact with our website.
        </p>
        <h2 className="text-2xl font-semibold text-indigo-600 dark:text-indigo-400 mt-4">How We Use Your Information</h2>
        <p>
          We use your information to provide and improve our services, communicate with you about deals and promotions, and analyze how our website is used.
        </p>
        <h2 className="text-2xl font-semibold text-indigo-600 dark:text-indigo-400 mt-4">Data Security</h2>
        <p>
          We implement security measures to protect your personal information from unauthorized access, alteration, or destruction.
        </p>
        <h2 className="text-2xl font-semibold text-indigo-600 dark:text-indigo-400 mt-4">Your Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.
        </p>
        <p>
          By using Burlington Deals, you agree to the terms outlined in this Privacy Policy.
        </p>
      </div>
    </motion.div>
  );
}

export default PrivacyPolicy;